<template>
  <div class="imgCon">
    <div
      class="imgConItem"
      v-if="!adsMsg.image"
    >
      <el-button
        size="small"
        @click="selectImg('video')"
        >从创意云选择</el-button
      >
      <el-button
        size="small"
        @click="creatSlide"
        >创建幻灯</el-button
      >
      <!-- <el-upload
        class="upload-demo"
        action="#"
        :http-request="uploadImg"
        :show-file-list="false"
        :before-upload="beforeUpload1"
      >
        <el-button
          size="small"
          type="primary"
          @click="uploadType('video')"
          >点击上传</el-button
        >
        <div
          slot="tip"
          class="el-upload__tip"
          style="line-height: 28px"
        >
          <p>推荐长宽比：1:1</p>
          <p>推荐尺寸：1080 * 1080像素</p>
          <p>最小尺寸：600 * 600像素</p>
          <p>图中文本内容不超过20%效果更佳</p>
        </div>
      </el-upload> -->
      <gm-upload-material
        ref="uploadMaterial"
        v-model:fileList="fileList"
        :saveAdFileParams="saveAdFileParam"
        :on-success="onSuccess"
        :before-upload="beforeUpload"
      >
        <el-button
          size="small"
          type="primary"
          class="inline-block ml-3"
          >点击上传</el-button
        >
        <div
          slot="tip"
          class="el-upload__tip"
        >
          <img-tips></img-tips>
        </div>
      </gm-upload-material>
    </div>
    <div
      class="imgConItem"
      v-else
    >
      <div
        class="mark-loading"
        v-show="!(adsMsg.videoStatus == 'ready' || adsMsg.videoStatus == 'error' || !adsMsg.videoStatus)"
        v-loading="!(adsMsg.videoStatus == 'ready' || adsMsg.videoStatus == 'error' || !adsMsg.videoStatus)"
        :element-loading-text="from === 'fb' ? (reset ? '重新上传' : '视频上传FB中...') : '视频上传中...'"
        @click="resetUpload"
      ></div>
      <div class="tools">
        <!-- <i class="el-icon-info"></i> -->
        <i class="el-icon-zoom-in"></i>
        <i
          class="el-icon-delete"
          v-if="adsMsg.videoStatus == 'ready' || adsMsg.videoStatus == 'error' || !adsMsg.videoStatus"
          @click="deleImg('video')"
        ></i>
        <!-- <el-upload
          class="upload-demo"
          action="#"
          :http-request="uploadImg"
          :show-file-list="false"
          style="display: inline-block"
          :before-upload="beforeUpload"
        >
          <i
            class="el-icon-upload2"
            @click="uploadType('video')"
          ></i>
        </el-upload> -->
      </div>
      <div class="imgTips">
        <p>推荐长宽比：1:1</p>
        <p>推荐尺寸：1080 * 1080像素</p>
        <p>最小尺寸：600 * 600像素</p>
        <p>图中文本内容不超过20%效果更佳</p>
      </div>
      <video
        controls
        :src="adsMsg.image"
        :poster="adsMsg.thumbUrl"
        v-if="fileType == 'mp4'"
      ></video>
      <el-image
        :src="adsMsg.image"
        :preview-src-list="[adsMsg.image]"
        v-if="fileType == 'gif'"
      ></el-image>
    </div>
    <img-list
      :imgListShow="imgListShow"
      :saveAdFileParam="saveAdFileParam"
      @close="close"
      @urlList="urlList"
      :uploadTypes="uploadTypes"
      :limit="1"
    ></img-list>
  </div>
</template>

<script>
import imgTips from '@/views/adManagement/createAd/components/adsComponents/imgTips.vue';
import { mediaFileUploadToFb, querySlideshowVideo, mediaFileUploadFbCleanDate } from '@/api/creatAd.js';
import imgList from './imgList.vue';
import tools from './tools.js';
import adsMethods from '../adsMethods';
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    accountId: {
      type: String | Number,
      default: '',
    },
    rowThirdNum: {
      type: String,
      default: '',
    },
    adsMsg: {
      type: Object,
      default: () => {},
    },
    from: {
      type: String,
      default: '',
    },
  },
  components: {
    imgList,
    imgTips,
  },
  mixins: [tools, adsMethods],
  data() {
    return {
      imgListShow: false,
      fileType: 'mp4',
      reset: false,
      formData: null,
      newTimer: null,
      uploadTime: 0,
      timer: null,
      uploadVideoFbId: '',
      uploadVideoFbClearNum: 0,
      uploadTypes: 'video',
      uploadVideoFbResult: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.newTimer);
    clearInterval(this.timer);
  },
  methods: {
    close(v) {
      this[v] = false;
    },
    uploadType() {
      this.$emit('uploadType', 'video');
    },
    creatSlide() {
      this.$emit('creatSlide');
    },
    deleImg() {
      this.$emit('deleImg', 'video', this.index);
    },
    selectImg() {
      this.imgListShow = true;
    },
    urlList(v) {
      this.chooseList = v;
      let img = v[v.length - 1];
      this.uploadVideoFbEdit(img.id, img.sourceType);
      this.$emit('setAdsMsg', this.index, {
        image: img.url,
        source_id: img.id,
        thumbUrl: img.thumbUrl,
        sourceType: img.sourceType,
        mediaSourceType: img.sourceType,
      });
    },
    // 上传文件
    // uploadImg(file) {
    //   let formData = new FormData();
    //   let key = '';
    //   if (this.adsMsg.media_type == 'video') {
    //     key = 'VIDEO';
    //   }
    //   let fileType = file.file.type;
    //   let isJPG =
    //     fileType == 'image/jpeg' || fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/webp';
    //   let isMP4 = fileType === 'video/mp4' || fileType === 'image/gif';
    //   if (!((key == 'VIDEO' && isMP4) || ((key = 'IMAGE') && isJPG))) {
    //     this.$message({
    //       type: 'warning',
    //       message: '素材类型不一致，请重新选择素材上传',
    //     });
    //     return false;
    //   }

    //   formData.append('file', file.file);
    //   formData.append('type', key);
    //   this.$showLoading();
    //   this.newUpload(formData, { type: key })
    //     .then((res) => {
    //       if (res.code == 0) {
    //         this.$message({
    //           type: 'success',
    //           message: '上传成功',
    //         });
    //         this.$emit('setAdsMsg', this.index, {
    //           image: res.data.url,
    //           source_id: res.data.id,
    //         });
    //         this.uploadVideoFb(res.data.id);
    //       }
    //     })
    //     .finally(() => {
    //       this.$hideLoading();
    //     });
    // },
    onSuccess(response, file) {
      console.log(response, file, 'onSuccess');
      if (response?.status == 'error') {
        this.$notify.error({
          title: '错误',
          message: `${response.name}上传失败`,
          duration: 0,
        });
        return;
      }
      this.$message({
        type: 'success',
        message: '上传成功',
      });
      this.$emit('setAdsMsg', this.index, {
        image: response.url,
        source_id: response.id,
        sourceType: response.sourceType,
        mediaSourceType: response.sourceType,
      });
      this.uploadVideoFbEdit(response.id, response.sourceType);
    },
    // 上传视频到FB
    uploadVideoFbEdit(mediaId, sourceType) {
      this.uploadVideoFbId = mediaId;
      this.uploadVideoFbClearNum = 0;
      let params = { mediaId, sourceType, accountId: this.accountId };
      this.formData = params;
      this.reset = false;
      this.timing();
      mediaFileUploadToFb(params).then((res) => {
        this.reset = false;
        if (res.code == 0) {
          this.uploadVideoFbResult = res.data;
          if (res.data.videoStatus === 'error') {
            this.cleanDateMediaFileUploadFb();
            return;
          }
          this.getVideoUrlEdit(res.data.videoId,res.data.sourceType);
          this.$emit('setAdsMsg', this.index, { uploadNums: 1 });
        } else {
          this.$emit('setAdsMsg', this.index, { videoStatus: 'error', image: '' });
        }
      });
    },
    // 计时
    timing() {
      // 计时  60s 后重新上传
      this.newTimer = setInterval(() => {
        this.uploadTime += 1;
        if (this.uploadTime >= 59) {
          this.uploadTime = 0;
          this.reset = true;
          clearInterval(this.newTimer);
        }
      }, 1000);
    },
    // 重新上传
    resetUpload() {
      if (this.reset) {
        this.reset = false;
        this.timing();
        // let formData = this.formData;
        clearTimeout(this.timer);

        let params = {
          accountId: this.$store.getters.accountId,
          sourceType: this.this.adsMsg.sourceType,
          mediaId: this.this.adsMsg.source_id,
        };
        if (this.uploadVideoFbResult?.videoId) {
          params.videoId = this.uploadVideoFbResult.videoId;
        }
        this.$showLoading();
        mediaFileUploadFbCleanDate(params).then((res) => {
          this.reset = false;
          if (res.code == 0) {
            this.getVideoUrlEdit(res.data.videoId, res.data.sourceType);
            this.$emit('setAdsMsg', this.index, { uploadNums: 1 });
          } else {
            this.$emit('setAdsMsg', this.index, { videoStatus: 'error', image: '' });
          }
        }).finally(() => {
          this.$hideLoading();
        })
      }
    },
    // 清空数据库视频数据，重新上传
    cleanDateMediaFileUploadFb() {
      this.uploadVideoFbClearNum += 1;
      // let formData = new FormData();
      // formData.append('mediaFileId', this.uploadVideoFbId);
      // formData.append('accountId', this.accountId);
      let params = {
          accountId: this.$store.getters.accountId,
          sourceType: this.adsMsg.sourceType,
          mediaId: this.adsMsg.source_id,
        };
        if (this.uploadVideoFbResult?.videoId) {
          params.videoId = this.uploadVideoFbResult.videoId;
        }
        this.$showLoading();
      mediaFileUploadFbCleanDate(params).then((res) => {
        if (res.code == 0) {
          this.getVideoUrlEdit(res.data.videoId, res.data.sourceType);
        }
      }).finally(() => {
        this.$hideLoading();
      })
    },
    // 轮训上传进度
    getVideoUrlEdit(id, sourceType) {
      clearTimeout(this.timer);
      querySlideshowVideo({sourceType, videoId: id, accountId: this.accountId })
        .then((res) => {
          if (res.code == 0) {
            console.log(res.data, 'getVideoUrlEdit');
            this.$emit('setAdsMsg', this.index, {
              videoStatus: res.data.videoStatus,
            });
            // this.$emit('videoStatus',res.data.videoStatus)
            if (res.data.videoStatus == 'processing') {
              if (this.adsMsg.cancleVideoUpload) return;
              this.timer = setTimeout(() => {
                this.getVideoUrlEdit(id, sourceType);
                this.$emit('setAdsMsg', this.index, {
                  uploadNums: this.adsMsg.uploadNums + 1,
                });
                // this.adsList[this.activeIndex].uploadNums+=1
              }, 5000);
            } else if (res.data.videoStatus == 'ready') {
              console.log('上传成功');
              clearInterval(this.newTimer);
              clearInterval(this.timer);
              this.$emit('setAdsMsg', this.index, {
                videoStatus: 'ready',
                uploadNums: 1,
              });
              this.$emit('setVideoUrl', res.data.mediaBaseInfo, res.data.thumbnails, this.index);
              this.$hideLoading();
            } else if (res.data.videoStatus == 'error' && this.uploadVideoFbClearNum < 3) {
              this.cleanDateMediaFileUploadFb();
            } else {
              this.$hideLoading();
              this.$emit('setAdsMsg', this.index, {
                videoStatus: 'error',
                uploadNums: 1,
                image: null,
                source_id: null,
                cancleVideoUpload: false,
              });
              // this.adsList[this.activeIndex].videoStatus = 'error';
              // this.adsList[this.activeIndex].image = null;
              // this.adsList[this.activeIndex].source_id = null;
              // this.adsList[this.activeIndex].uploadNums = 1;
              // this.adsList[this.activeIndex].cancleVideoUpload = false
              console.log('---1');
              this.$message({
                type: 'warning',
                message: '失败,请重新上传视频',
              });
            }
            this.$forceUpdate();
          } else {
            this.$hideLoading();
            this.$emit('setAdsMsg', this.index, {
              videoStatus: 'error',
              uploadNums: 1,
              image: null,
              source_id: null,
              cancleVideoUpload: false,
            });
            this.$message({
              type: 'warning',
              message: '失败,请重新上传视频',
            });
          }
        })
        .catch((res) => {
          this.timer = setTimeout(() => {
            this.getVideoUrlEdit(id, sourceType);
          }, 5000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.imgCon {
  padding: 20px !important;
}
.imgConItem {
  width: 208px;
  height: 208px;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  position: relative;
  .mark-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
  }
  video {
    width: 208px !important;
    height: 208px !important;
  }
}
</style>
